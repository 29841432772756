/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  .container {
    max-width: 960px;
  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

  .a {
    .button {
      border: 1px solid #212121;
      padding: 6px 20px;
    }
  }

  .product-name {
    text-align: center;
  }

  .product-price {
    text-align: center;
  }
  .button-container {
    margin: 20px 0;

    .button-container-catalogs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }
  }

  .product-section-image {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #979797;
    padding: 30px;
    text-align: center;
  }

  .featured-section {

    .products {
      grid-template-columns: 1fr 1fr;
    }
  }

  .products-header {
    flex-direction: column;
    margin-bottom: 48px;
  }

  .blog-section {
    .blog-posts {
      grid-template-columns: 1fr;
      text-align: center;
      grid-template-areas: "blog3" "blog2" "blog1";

      .blog-post {
        margin-top: 30px;
      }

    }
  }

  .footer-content {
    flex-direction: column;

    .made-with {
      margin: auto;
    }

    ul {
      margin: 20px auto;
      width: 60%;
    }
  }

  .breadcrumbs {
    text-align: center;

    .breadcrumbs-container {
      flex-direction: column;
    }
  }

  .products-section {
    grid-template-columns: 1fr 1fr;

    .sidebar {
      text-align: center;
    }

    .products {
      grid-template-columns: 1fr;
    }
  }

  .map-section {
    grid-template-columns: 1fr;

    .sidebar {
      text-align: center;
    }

    .products {
      grid-template-columns: 1fr;
    }
  }

  .search-section {
    grid-template-columns: 1fr;

    .products-searched {
      grid-template-columns: 1fr;
    }
  }

  .product-section {
    grid-template-columns: 1fr 1fr;
  }

  .might-like-section .might-like-grid {
    grid-template-columns: 1fr 1fr;
  }

  .cart-section {

    grid-template-columns: 1fr;

    .cart-buttons {
      flex-direction: column;
      text-align: center;
    }

    .cart-table-row {

      .cart-table-row-left {
        width: 30%;
      }

      .cart-table-row-right {
        width: 55%;
      }
    }

    .have-code-container {
      width: 100%;
    }

    .cart-totals {
      flex-direction: column;

      .cart-totals-left {
        width: 100%;
        margin-bottom: 20px;
      }

      .cart-totals-right {
        width: 100%;
      }
    }

  }


  .checkout-section {
    grid-template-columns: 1fr;

    .checkout-table-container {
      margin-left: 10px;
    }
  }

  .search-results-container-algolia {
    grid-template-columns: 1fr;
  }

  .ais-refinement-list--count {
    margin-left: auto;
    margin-right: 10px;
  }

  .auth-pages {
    margin: 50px auto;
    grid-template-columns: 1fr;
    grid-gap: 40px;

    .auth-right {
      border-left: none;
      padding-left: 20px;
    }
  }

  .my-profile input {
    width: 100%;
  }

  .my-orders .order-header-items {
    display: block;
  }


  .my-orders .order-header-items > div {
    margin-bottom: 14px;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 920px) {
  .a {
    .button {
      border: 1px solid #212121;
      padding: 6px 20px;
    }
  }
  .container {
    margin-left: 5px;
    margin-right: 5px;
  }

  .product-name {
    text-align: center;
  }

  .product-price {
    text-align: center;
  }
  .button-container {
    margin: 20px 0;

    .button-container-catalogs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }
  }

}


/* Extra Small Devices, Phones */
@media only screen and (max-width: 760px) {

  .product-section {
    display: block;

    .product-section-information {
      .product-section-title {
        font-size: 24px;
      }

      .product-section-subtitle {
        font-size: 18px;
      }
    }
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 630px) {

  .search-box {
    width: 15.5em;
  }

  .product-section {
    display: block;

    .product-section-information {
      .product-section-title {
        font-size: 24px;
      }

      .product-section-subtitle {
        font-size: 18px;
      }
    }
  }

  .container-menu {
    padding-left: 0px !important;
  }

  .collapsed {
    margin-left: -100% !important;
  }

  .featured-section {

    .products {
      grid-template-columns: 1fr 1fr;
    }
  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .button {
    border: 1px solid #212121;
    padding: 6px 20px;
  }

  .sidebar-nav-1 {
    width: 100%;
  }

  .search-box {
    width: 17.5em;
  }

  .container {
    margin-left: 5px;
    margin-right: 5px;
  }

  .product-name {
    text-align: center;
  }

  .product-price {
    text-align: center;
  }
  .button-container {
    margin: 20px 0;

    .button-container-catalogs {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }


}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {

}
